export const SnotifyAlert = {
  data: function() {
    return {
      flashMessages: window.flashMessages,
      notifications: {
        alert: [],
        custom: []
      }
    };
  },
  methods: {
    showAlert: function(type, value, options = {}) {
      if (type == 'alert-error' || type == 'alert-danger') {
        this.showErrorAlert(value, options);
      } else if (type == 'alert-success') {
        this.showSuccessAlert(value, options);
      } else {
        this.showNoticeAlert(value, options);
      }
    },
    showErrorAlert: function(value, options) {
      var toastCreated = this.$snotify.error(this._getValue(value).text, { buttons: this._getValue(value).buttons, ...options })
        .on('destroyed', toast => this.onNotificationDestroy(toast));
      this.onNotificationCreated(toastCreated.id);
    },

    showSuccessAlert: function(value, options) {
      var toastCreated = this.$snotify.success(this._getValue(value).text, { buttons: this._getValue(value).buttons, ...options })
        .on('destroyed', toast => this.onNotificationDestroy(toast));
      this.onNotificationCreated(toastCreated.id);
    },

    showNoticeAlert: function(value, options) {
      var toastCreated = this.$snotify.info(this._getValue(value).text, { buttons: this._getValue(value).buttons, ...options })
        .on('destroyed', toast => this.onNotificationDestroy(toast));
      this.onNotificationCreated(toastCreated.id);
    },
    _isJson: function(value) {
      if (typeof value == 'object') {
        return true;
      }
      try {
        return !!JSON.parse(value);
      } catch (e) {
        return false;
      }
    },
    _getValue: function(value) {
      if (this._isJson(value)) {
        let parsedJSON = typeof value == 'object' ? value : JSON.parse(value);
        return { text: parsedJSON.text, buttons: this._getButtons(parsedJSON.buttons) };
      } else {
        return { text: value, buttons: [] };
      }
    },
    _getButtons: function(buttonsHash) {
      return buttonsHash.map(function(val) {
        let parsedButton = JSON.parse(val),
          buttonJSON = { text: parsedButton.text };

        if (parsedButton.type == 'link') {
          buttonJSON['action'] = function() {
            if(window.location.href === parsedButton.href) {
              window.location.reload();
            } else {
              window.location = parsedButton.href;
            }
          };
        } else if (parsedButton.type == 'reload') {
          buttonJSON['action'] = function() {
            window.location.reload();
          };
        }
        return buttonJSON;
      });
    },
    showHtmlAlert: function({ name, data }) {
      const position = 'rightTop', customClass = 'snotify-light-theme';
      var toastCreated = this.$snotify.html('', { position, component: { name, customClass, data } })
        .on('destroyed', toast => this.onNotificationDestroy(toast, 'custom'));
      this.onNotificationCreated(toastCreated.id, 'custom');
    },
    onNotificationCreated(id, type = 'alert') {
      this.notifications[type].push(id);
    },
    onNotificationDestroy({id}, type = 'alert') {
      this.notifications[type] = this.notifications[type].filter(toastId => {
        return toastId != id;
      });
    },
    clearAll(type){
      this.notifications[type].map( id => {
        this.$snotify.remove(id, true);
      });
      this.notifications[type] = [];
    }
  },
  mounted: function() {
    var _this = this;
    for (let flash of this.flashMessages) {
      if (flash['value']) {
        _this.showAlert(flash['type'], flash['value']);
      }
    }
    $('body').on('showFlash', function(e, data) {
      _this.showAlert(data['type'], data['value'], data['options']);
    });
  }
};
