export const getExactHours = function(duration) {
  return Math.abs(parseInt(Math.abs(duration) / 3600)) || 0;
};

export const getMinutes = function(duration) {
  var hours = getExactHours(duration);
  return Math.abs(Math.round(((Math.abs(duration) / 3600) - hours) * 60));
};

export const hoursAndMinutes = function(value) {
  var hours = getExactHours(value),
    minutes = getMinutes(value);
  return hours.toString() + ':' + minutes.toString().padStart(2, '0');
}
