import Vue from 'vue/dist/vue.esm';

export const truncate = Vue.filter('truncate', function(truncatableText) {
  var truncatedText = truncatableText.split(' ').splice(0, 30).join(' ');

  if(truncatedText.length < truncatableText.length) {
    return truncatedText + '...';
  } else {
    return truncatedText;
  }
});
