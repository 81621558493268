import Vue from 'vue/dist/vue.esm'
import * as deepmerge from 'deepmerge'
import { SharedLocale } from "./all";
import VueI18n from 'vue-i18n';
import { taskModalLocale } from 'locales/tasks/locale.js'

Vue.use(VueI18n)

const LocaleHash = deepmerge(SharedLocale, taskModalLocale);

export const i18n = new VueI18n({
  locale: 'en',
  messages: LocaleHash
});
