import Vue from 'vue/dist/vue.esm';
import { formatTime } from 'concerns/time_format.js'
import { formatDateAndTime } from 'concerns/format_date_and_time.js'

export const dateHumanReadable = Vue.filter('dateHumanReadable', function(value) {
  if (value) {
    var daysDiff = moment().diff(moment(value), 'days');
    switch(daysDiff) {
      case 0:
        return 'Today, ' + formatTime(value);
        break;
      case 1:
        return 'Yesterday, ' + formatTime(value);
        break;
      default:
        return formatDateAndTime(value);
    }
  }
  else {
    return value;
  }
});
