import Vue from 'vue/dist/vue.esm'

export const downCase = Vue.filter('downCase', function(value) {
  if (value) {
    return value.toLowerCase();
  }
  else {
    return value;
  }
});
