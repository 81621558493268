import Vue from 'vue/dist/vue.esm'

export const formatSIDate = Vue.filter('formatSIDate', function(value) {
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mm::ss').format('MM/DD/YYYY');
  }
  return '';
});

export const formatSIDateWithTimeZone = Vue.filter('formatSIDateWithTimeZone', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('MM/DD/YYYY');
  }
  return '';
});

export const formatSIDateTime = Vue.filter('formatSIDateTime', function(value) {
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mm::ss').format('DD[th] MMMM - LT');
  }
  return '';
});

export const formatSIDateWithTime = Vue.filter('formatSIDateWithTime', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('MM/DD/YYYY - hh:mm A');
  }
  return '';
});

export const formatSIExpandedDateTime = Vue.filter('formatSIExpandedDateTime', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('DD MMMM YYYY, hh:mm A');
  }
  return '';
});

export const formatSIExpandedDateTimeWithHyphen = Vue.filter('formatSIExpandedDateTimeWithHyphen', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('Do MMMM YYYY - hh:mm a');
  }
  return '';
});

export const formatSITime = Vue.filter('formatSITime', function(value) {
  if (value) {
    return moment(value).format('hh:mm A');
  }
  return '';
});

export const formatSITimeZone = Vue.filter('formatSITimeZone', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('hh:mm A');
  }
  return '';
});

export const formattedTime = Vue.filter('formattedTime', function(value) {
  if (value) {
    if(isToday(value)) {
      return 'Today, ' + formatSITime(value);
    } else if(isYesterday(value)) {
      return 'Yesterday, ' + formatSITime(value);
    } else {
      return formatSIExpandedDateTime(value);
    }
  }
  return '';
});
// Need to confirm this work fine
export const isToday = function(value) {
  var today = moment().startOf('day');
  return isSameDate(value, today);
}

export const isYesterday = function(value) {
  var yesterday = moment().subtract(1, 'days').startOf('day');
  return isSameDate(value, yesterday);
}

// Assuming secondDate is moment object
export const isSameDate = function(firstDate, secondDate) {
  if(firstDate && secondDate) {
    return secondDate.isSame(firstDate, 'd');
  }
  return false;
}

export const formatSiCollapsedDateTime = Vue.filter('formatSiCollapsedDateTime', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('MM/DD/YYYY - hh:mm A');
  }
  return '';
});

export const dateAndMonth = Vue.filter('dateAndMonth', function(value) {
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mm::ss').format('DD MMMM');
  }
  return '';
});

export const formatSiDateTimeCompact = Vue.filter('formatSiDateTimeCompact', function(value) {
  if (value) {
    return moment(value).format('DD MMMM YYYY, hh:mm A');
  }
  return '';
});

export const formatSiDateExpanded = Vue.filter('formatSiDateExpanded', function(value) {
  if (value) {
    return moment(value).tz('America/Los_Angeles').format('DD MMMM YYYY');
  }
  return '';
});

