import Vue from 'vue/dist/vue.esm'
import { SharedLocale  } from "locales/shared/all.js";
import * as deepmerge from 'deepmerge'
import VueI18n from 'vue-i18n';

export const taskModalLocale = {
  en: {
    tasks: {
      task_count: '0 Task | {count} Task | {count} Tasks'
    },
    task_modal: {
      title: '%{id} - %{task_category}',
      title_placeholder: 'Enter Title',
      description_placeholder: 'Enter Description',
      cancelled_text: 'Task was marked as canceled on ',
      mark_as_complete: 'Mark as Complete',
      completed: 'Marked Completed',
      tabs: {
        details: 'Details',
        history: 'History',
        timelogs: 'Time Logs',
        comments: 'Comments'
      },
      details: {
        due_date_label: 'Due Date',
        assignee_label: 'Assignee',
        time_log_label: 'Time Logged',
        followers_label: 'Followers',
        category_label: 'Category',
        referenced_label: 'Referenced In',
        formatted_time: '%{time} hr',
        attachments_label: 'Attachments',
        created_by: 'Task created by',
        last_edited: 'Last Edited: %{edited}'
      }
    },
    task_time_log_modal: {
      title: '%{title}',
      duration_placeholder: 'Time (6:30)',
      notes_placeholder: 'Add notes to this time entry',
    },
    comments_list: {
      edit: 'Edit',
      cancel: 'Cancel',
      delete: 'Delete',
      details: '#%{id} - %{body}',
      delete_heading: 'Delete Comment',
      confirmation_message: 'Are you sure you want to delete this comment?',
    }
  }
}

const LocaleHash = deepmerge(SharedLocale, taskModalLocale);

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  messages: LocaleHash
});
