import { dateHumanReadable } from 'filters/dateHumanReadable';
import { formatDateTime } from 'filters/formattedDateTime';
import { formatTimeDuration, formatTimeDurationInWords, formatHoursAndMinutesInWords } from 'filters/formatTimeDuration';
import { formatSIDate, formatSiDateExpanded, dateAndMonth, formatSiDateTimeCompact, formatSIExpandedDateTime, formatSiCollapsedDateTime, formatSIExpandedDateTimeWithHyphen, formatSITimeZone } from './formatSIDate';
import { formatBoolean } from 'filters/formatBoolean';
import { parseDateTime } from 'filters/parseDateTime';
import { truncate } from 'filters/truncate';
import { codeWithDash } from 'filters/codeWithDash';
import { downCase } from 'filters/downCase';
import { replaceSpace } from 'filters/replaceSpace';
import { withFormattedCurrency } from 'filters/withFormattedCurrency';
import { withPrefixHash } from 'filters/withPrefixHash';
import { humanize } from 'filters/humanize';
import { withSpecialChar } from 'filters/withSpecialChar';
import { formatNumber } from 'filters/formatNumber';
import { withFormattedCurrencyAndNoDecimal } from 'filters/withFormattedCurrencyAndNoDecimal';

export default {
  codeWithDash,
  formatDateTime,
  formatTimeDuration,
  formatTimeDurationInWords,
  formatHoursAndMinutesInWords,
  formatSiCollapsedDateTime,
  formatSIExpandedDateTimeWithHyphen,
  formatSIDate,
  humanize,
  formatSiDateExpanded,
  formatSIExpandedDateTime,
  formatBoolean,
  formatSITimeZone,
  formatSiDateTimeCompact,
  withFormattedCurrency,
  dateAndMonth,
  parseDateTime,
  truncate,
  downCase,
  replaceSpace,
  dateHumanReadable,
  withPrefixHash,
  withSpecialChar,
  formatNumber,
  withFormattedCurrencyAndNoDecimal
};
