import Vue from 'vue/dist/vue.esm';

import Snotify from 'vue-snotify/dist';

const DEFAULT_SNOTIFY_CONFIGURATION = {
  toast: {
    timeout: gon.flashAutoHideMilliseconds,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progressBarDirection: 'rtl',
    position: 'rightBottom'
  }
};

Vue.use(Snotify, DEFAULT_SNOTIFY_CONFIGURATION);
