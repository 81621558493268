import Vue from 'vue/dist/vue.esm'
import { hoursAndMinutes } from 'concerns/time_duration_methods.js';
import { hoursAndMinutesWithoutConversion } from 'concerns/time_duration_methods_without_conversion.js'
import { i18n } from 'locales/shared/locale.js';

export const formatTimeDuration = Vue.filter('timeDurationFormat', function(value, convertIntoHours = true){
  if (convertIntoHours) {
    return hoursAndMinutes(value);
  } else {
    return hoursAndMinutesWithoutConversion(value);
  }
});

export const formatTimeDurationInWords = Vue.filter('timeDurationInWordsFormat', function (value) {
  let [hours, mins] = hoursAndMinutes(value).split(':');
  hours = i18n.tc('timeDurationInWordsFormat.hours', hours);
  mins = i18n.tc('timeDurationInWordsFormat.mins', mins);
  return [hours, mins].join(' ').trim();
});

export const formatHoursAndMinutesInWords = Vue.filter('hoursAndMinutesInWordsFormat', function (value) {
  let [hours, mins] = value.split(':');
  hours = i18n.tc('timeDurationInWordsFormat.hours', hours);
  mins = i18n.tc('timeDurationInWordsFormat.mins', mins);
  return [hours, mins].join(' ').trim();
});
