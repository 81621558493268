import Vue from 'vue/dist/vue.esm'

export const replaceSpace = Vue.filter('replaceSpace', function(value) {
  if (value) {
    return value.replace(' ', '_');
  }
  else {
    return value;
  }
});
