import Vue from 'vue/dist/vue.esm';

export const globalNotificationListItem = Vue.component('notification-list-item', {
  template: `
  <ul class="list-unstyled notification-listing" @click="redirectToNotification(data)">
    <li class="notification-list-item d-flex pointer">
      <div class="user-image-space-area m-r-15">
        <div class='card-assign-user x-32' v-if="data.icon_class_name" >
          <span :class="data.icon_class_name"></span>
        </div>
        <span v-else class="card-assign-user x-24" v-html="data.profile_picture"></span>
      </div>

      <div data-behavior="notification_clamped_content">
        <div class="notification-text" v-html="data.formatted_content">
        </div>
        <span class="fs-12 notification-date">
          {{ data.created_at | formatDate }}
        </span>
      </div>
    </li>
  </ul>
  `,
  props: ['data'],
  methods: {
    redirectToNotification({ id, entity_url, object_type, object_id }) {
      this.$root.$store.dispatch('NotificationWidgetModule/markNotificationAsRead', {
        apollo: this.$apollo,
        data: { notificationId: id }
      }).then(() => {
        if(object_type == 'Task'){
          $(document).trigger('open-task-modal', { id: object_id });
        } else {
          window.location.href = entity_url;
        }
      });
    }
  }
});
