import Vue from 'vue/dist/vue.esm'
import { formatDateAndTime } from 'concerns/format_date_and_time.js';

export const formatDateTime = Vue.filter('formatDate', function(value) {
  if (value) {
    return formatDateAndTime(value);
  }
  return '';
});

