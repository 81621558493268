import Vue from 'vue/dist/vue.esm';

export const humanize = Vue.filter('humanize', function(value) {
  if (value) {
    return _.startCase(value)
  }
  else {
    return value;
  }
});
